<script setup>
import { useStore } from 'vuex';
import SiteHeader from '@/components/widgets/site-header';
import PageLayout from '@/components/layouts/page-layout';
import SidebarBrands from '@/components/widgets/sidebar-brands';
import ContactForm from '@/components/widgets/contact-form';

const store = useStore();
</script>

<template lang="html">
  <SiteHeader />
  <PageLayout sidebar-right>
    <template #mainContent>
      <div class="contacts-page">
        <div v-html="store.state.contacts.page" class="page-content q-py-md q-px-sm"></div>
        <ContactForm />
      </div>
    </template>
    <template #rightSidebar>
      <SidebarBrands />
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import '~quasar-variables';

</style>
